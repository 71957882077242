<template>
  <div style="height: inherit">
    <section
      :class="itemView"
    >
      <b-card
        v-for="(item, index) in items"
        :key="item.id"
        class="ecommerce-card"
        no-body
      >
        <div class="item-img text-center">
          <b-link @click="addNewReport(item)">
            <b-img
              :alt="`${item.label}`"
              fluid
              class="card-img-top"
              :src="imageIcons[index]"
            />
          </b-link>
        </div>
        <!-- body -->
        <b-card-body>
          <h6 class="item-name">
            {{ item.label }}
          </h6>
        </b-card-body>
        <!-- Actions -->
        <div class="item-options text-center">
          <b-button
            variant="light"
            tag="a"
            class="btn-wishlist"
            @click="listReportsByType(item)"
          >
            <span>List</span>
          </b-button>
          <b-button
            variant="primary"
            tag="a"
            class="btn-cart"
            @click="addNewReport(item)"
          >
            <span>Add Report</span>
          </b-button>
        </div>
      </b-card>
    </section>
  </div>
</template>

<script>
import {
  BCard, BCardBody, BImg, BLink, BButton,
} from 'bootstrap-vue'
import store from '@/store'
import { ref } from '@vue/composition-api'

// eslint-disable-next-line camelcase
import alarm_response from '@/assets/images/reports/alarm_response.png'
// eslint-disable-next-line camelcase
import general_occurrence from '@/assets/images/reports/general_occurrence.png'
// eslint-disable-next-line camelcase
import internal_useof_force from '@/assets/images/reports/internal_useof_force.png'
// eslint-disable-next-line camelcase
import parking_enforcement from '@/assets/images/reports/parking_enforcement.png'
// eslint-disable-next-line camelcase
import parking_permits from '@/assets/images/reports/parking_permits.png'
// eslint-disable-next-line camelcase
import security_log from '@/assets/images/reports/security_log.png'
// eslint-disable-next-line camelcase
import trespass_notice from '@/assets/images/reports/trespass_notice.png'
// eslint-disable-next-line camelcase
import vehicle_log from '@/assets/images/reports/vehicle_log.png'

export default {
  components: {
    BCard, BCardBody, BImg, BLink, BButton,
  },
  setup() {
    const items = ref([])
    const itemView = 'grid-view'
    const itemViewOptions = [
      { icon: 'GridIcon', value: 'grid-view' },
      { icon: 'ListIcon', value: 'list-view' },
    ]

    items.value = store.state.appSettings.report_types

    return {
      items,
      itemView,
      itemViewOptions,
    }
  },
  data() {
    return {
      // eslint-disable-next-line global-require, camelcase
      imageIcons: [alarm_response, general_occurrence, internal_useof_force, parking_enforcement, parking_permits, security_log, trespass_notice, vehicle_log],
    }
  },
  methods: {
    listReportsByType(item) {
      const reportType = item.value
      console.log('listReportsByType')
      console.log(reportType)
      const nextRout = { name: 'apps-reports-list', params: { currentReportType: reportType } }
      // Route user
      this.$router.replace(nextRout)
      // apps-reports-list
    },
    addNewReport(item) {
      const reportType = item.value
      console.log(reportType)
      const nextRout = { name: `apps-reports-${reportType}`, params: { currentReportType: reportType } }
      // Route user
      this.$router.replace(nextRout)
      // apps-reports-list
    },
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
</style>

<style lang="scss" scoped>
.item-view-radio-group ::v-deep {
  .btn {
    display: flex;
    align-items: center;
  }
}
</style>
